//Import React Library
import React, { useState } from 'react'

//Load states from state management provider
import { useSubcategoriesContext } from "./contextSubcategories"
import { useGlobalContext } from "../../../Context/context"

//Import components from Lexis UI Library
import { Alert, makeAnimated, Select } from "@lexis/ui-library"

//Import fucntion from LHL admin api component
import { lhlSaveSubcategory, lhlUpdateSubcategory, lhlFindSubcategory } from "@lexis/admin-api"

//define helpers
const animatedComponents = makeAnimated();


export default function ModalCategories(props) {
    const { fetchSubcategories, categorias } = useGlobalContext();
    const { idSubcategoria, action } = useSubcategoriesContext();
    const [formCategoria, setFormCategoria] = useState(null)
    const [formLink, setFormLink] = useState(null)
    const [formTitulo, setFormTitulo] = useState(null)
    const [alertMessage, setAlertMessage] = useState("")
    const [alertVariant, setAlertVariant] = useState("")
    const [visible, setVisible] = useState(false);




    React.useEffect(() => {
        
        if ((action === "edit") & (idSubcategoria !== null)) {
            lhlFindSubcategory(idSubcategoria).then((data) => {
                console.log("find subcategoria: ", data)
                //let categoryId=subcategorias.filter(subcategoria=>subcategoria.value=== data.data.idSubcategoria)[0].idCategoria;
                //let categoryName=categorias.filter(categoria=>categoria.id=== categoryId)[0].categoria;
                setFormCategoria({ value: data.data[0].categoria.id, label: data.data[0].categoria.categoria})

                setFormLink(data.data[0].link)
                setFormTitulo(data.data[0].subcategoria)

            })
        }
    }, [idSubcategoria, action])

    React.useEffect(() => {
        
        if (action === "add") {

            resetFormValues()

        }
    }, [action])

    const resetFormValues = () => {
        setFormCategoria("")

        setFormTitulo("")
        setAlertMessage("")
        setAlertVariant("")
        setFormLink("")
        setVisible(false)

    }


    const handleSubmitAction = () => {

        if (action === "add") {
            lhlSaveSubcategory(formCategoria ? formCategoria.value : null, formTitulo, formLink).then((data) => {
                console.log("save categoria: ", data)
                handleApiResponse(data)
            })
            
        }
        else {
            lhlUpdateSubcategory(idSubcategoria, formCategoria ? formCategoria.value : null, formTitulo, formLink).then((data) => {
                console.log("edit categoria: ", data)
                handleApiResponse(data)
            })
            
        }
    }

    const handleApiResponse = (data) => {
        if (data.state === "SUCCESS") {
            setAlertMessage("Proceso Exitoso!")
            setAlertVariant("success")
            setVisible(true)
            //resetFormValues()
            console.log("fetchSubcategories inside handleApiResponses")
            fetchSubcategories(categorias)
        }
        else {
            let errorMsg = ""
            if (data.error === 400) { errorMsg = "Por favor llene todos los campos requeridos" }
            setAlertMessage("Error: " + errorMsg)
            setAlertVariant("danger")
            setVisible(true)
        }
    }
    return (
        <div className="row mx-0 w-100">
            <div className="col-12 py-2">
                <label for="ftitulo" className="form-label">Nombre *</label>
                <input type="text" className="form-input px-4" id="ftitulo" name="ftitulo" onChange={e => setFormTitulo(e.target.value)} value={formTitulo} />
            </div>

            <div className="col-12 py-2">
                <label className="form-label">Categoría *</label>
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    placeholder="Categoría"
                    value={formCategoria}
                    //defaultValue={categorias?categorias[1]:null}
                    onChange={e => (setFormCategoria(e))}
                    options={categorias}
                />
            </div>

            <div className="col-12 py-2">
                <label for="flink" className="form-label">Link</label>
                <input type="text" className="form-input px-4" id="flink" name="flink" onChange={e => setFormLink(e.target.value)} value={formLink} />
            </div>



            <button className="btn my-button mx-auto my-3" onClick={() => handleSubmitAction()}>{action === "add" ? "Añadir Sub-categoría" : "Editar Sub-categoría"}</button>


            {visible && <Alert variant={alertVariant} onClose={() => setVisible(false)} dismissible className="w-100">
                <p>
                    {alertMessage}
                </p>
            </Alert>}
        </div>
    )

}
