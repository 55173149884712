//Import React Library
import React from "react";

//Import App component
import App from "./AdminLhlApp"

//Import state management provider
import {GlobalProvider} from "./Context/context"

//This function loads the state management provider and App component
export default function Root(props) {
  return (
    <GlobalProvider>
      <App/>
    </GlobalProvider>
  );
}
