//Import React Library
import React, { useState } from "react";

//Import components from Lexis UI Library
//A custom title
import { SectionTitle } from "@lexis/ui-library"
//An + icon
import { IoMdAdd } from "@lexis/ui-library"
//A router from react-router-dom
import { BrowserRouter } from "@lexis/ui-library"

//Import components

import Counters from "./Components/Reusable/Counters/Counters"
import Router from "./Components/Router/Router"
//Import state management provider
import { useGlobalContext } from "./Context/context"

//This function displays a section title, counters, filters,tables and modals
export default function App(props) {
    //Define states
    const [show, setShow] = useState(false)
    const [modalTitle, setModalTitle] = useState("")
    const [action, setAction] = useState("add")
    const [libroId, setLibroId] = useState(null)
    const [idMateria, setidMateria] = useState(null)
    const [idSubcategoria, setidSubcategoria] = useState(null)
    const [idCategoria, setidCategoria] = useState(null)
    const { section } = useGlobalContext()

    //Handle clic on "New" button
    const handleNewClic = () => {
        if (section === "Libros") {
            setModalTitle("Nuevo Libro")
        }
        else if (section == "Materias") {
            setModalTitle("Nueva Materia")
        }
        else if (section == "Sub-categorías") {
            setModalTitle("Nueva Sub-categoría")
        }
        else if (section == "Categorías") {
            setModalTitle("Nueva Categoría")
        }
        setAction("add")
        setShow(true)
    }
    return (

        <div className="w-100 text-left py-4 px-3 px-md-4 " style={{ minWidth: "99vw", maxWidth: "100vw" }}>
            <SectionTitle color="var(--primaryText)" title="Biblioteca LHL" />
            <BrowserRouter>
                <Counters />
                
                <Router />
            </BrowserRouter>


        </div>


    );
}
