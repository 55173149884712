//Import React Library
import React from 'react'

//Import state management provider
import { CategoriesProvider } from "./contextCategories"

//Import Categories Layout
import Categories from "./Categories"
export default function Index() {

    return (
        <CategoriesProvider>
            <Categories/>
        </CategoriesProvider>
    )
}
