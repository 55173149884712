//Import React Library
import React from 'react'

//Import state management provider
import { SubjectsProvider } from "./contextSubjects"

//Import Categories Layout
import Subjects from "./Subjects"
export default function Index() {

    return (
        <SubjectsProvider>
            <Subjects/>
        </SubjectsProvider>
    )
}
