import React from 'react'

import { DataTable, FaPencilAlt, FaTrash, FaBookReader } from "@lexis/ui-library"

export default function GenericTable(props) {
    return (
        <DataTable
            
            title={props.title}
            columns={props.columns}
            data={props.data}
            pagination
            actions={<button className="btn my-button my-auto py-2 px-3 mx-0" onClick={() => props.handleNewClic()}>{props.newBtnLabel}</button>}
            //contextMessage="Context"
            contextActions={<p>delete</p>}
            paginationComponentOptions={{ rowsPerPageText: 'Filas por página:', rangeSeparatorText: 'de', noRowsPerPage: false, selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
            //paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1                
            //selectableRows
            //selectableRowsVisibleOnly
            persistTableHead

        />
    )
}
