import React, { useState, useContext } from 'react'
import { lhlCategories, lhlSubcategories, lhlMaterias, lhlFindPaises, lhlFindLibros } from "@lexis/admin-api"

//Import global context
import { useGlobalContext } from "../../../Context/context"
// make a new context
export const CategoriesContext = React.createContext();
export const useCategoriesContext = () => useContext(CategoriesContext);

// create the provider
export const CategoriesProvider = (props) => {
    const { categorias } = useGlobalContext()
    const [filteredCategorias, setFilteredCategorias] = useState(null)
    const [textInput, setTextInput] = useState(null)
    const [visibleModal, setVisibleModal] = useState(false)
    const [modalTitle, setModalTitle] = useState(false)
    const [action, setAction] = useState("add")
    const [idCategoria, setidCategoria] = useState(null)
    

    React.useEffect(() => {
        setFilteredCategorias(categorias)
    }, [categorias])

    React.useEffect(() => {
        let temp = categorias && textInput ? categorias.filter(categoria => categoria.categoria.toLowerCase().includes(textInput.toLowerCase())):categorias
        setFilteredCategorias(temp)
        console.log("filteredCategorias: ", temp)
    }, [textInput])

    const handleTextInputChange = (value) => {
        setTextInput(value)
        console.log("Text Input:", value)
    }


    return (
        <CategoriesContext.Provider value={{
            filteredCategorias, setFilteredCategorias,
            textInput, setTextInput,
            handleTextInputChange,
            visibleModal, setVisibleModal,
            modalTitle, setModalTitle,
            action, setAction,
            idCategoria, setidCategoria
        }}>
            {props.children}
        </CategoriesContext.Provider>
    )
}


