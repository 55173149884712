import React, { useState, useContext } from 'react'
import { lhlCategories, lhlSubcategories, lhlMaterias, lhlFindPaises, lhlFindLibros } from "@lexis/admin-api"

//Import global context
import { useGlobalContext } from "../../../Context/context"
// make a new context
export const AccessTypeContext = React.createContext();
export const useAccessTypeContext = () => useContext(AccessTypeContext);

// create the provider
export const AccessTypeProvider = (props) => {
    const { tipoAccesos } = useGlobalContext()
    const [filteredAccessTypes, setFilteredAccessTypes] = useState(null)
    const [textInput, setTextInput] = useState(null)
    const [visibleModal, setVisibleModal] = useState(false)
    const [modalTitle, setModalTitle] = useState(false)
    const [action, setAction] = useState("add")
    const [idAccessType, setIdAccessType] = useState(null)
    

    React.useEffect(() => {
        setFilteredAccessTypes(tipoAccesos)
    }, [tipoAccesos])

    React.useEffect(() => {
        let temp = tipoAccesos && textInput ? tipoAccesos.filter(acceso => acceso.tipoAcceso.toLowerCase().includes(textInput.toLowerCase())):tipoAccesos
        console.log("🚀 ~ file: contextAccessType.js ~ line 27 ~ React.useEffect ~ temp", temp)
        console.log("🚀 ~ file: contextAccessType.js ~ line 27 ~ React.useEffect ~ temp", tipoAccesos)
        
        setFilteredAccessTypes(temp)
    }, [textInput])

    const handleTextInputChange = (value) => {
        setTextInput(value)
        console.log("Text Input:", value)
    }


    return (
        <AccessTypeContext.Provider value={{
            filteredAccessTypes, setFilteredAccessTypes,
            textInput, setTextInput,
            handleTextInputChange,
            visibleModal, setVisibleModal,
            modalTitle, setModalTitle,
            action, setAction,
            idAccessType, setIdAccessType
        }}>
            {props.children}
        </AccessTypeContext.Provider>
    )
}


