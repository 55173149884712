//Import React Library
import React from 'react'
//Import components from Lexis UI Library
import { Modal } from "@lexis/ui-library"

//Import styles
//import "./modal.scss"

//This function provides a Modal with a form to reate and edit categories
export default function GenericModal(props) {


    return (
        <Modal
            show={props.show}
            onHide={() => props.onHide()}
            dialogClassName="modal-90w"
            backdrop="static"
            size="lg"
            aria-labelledby="example-custom-modal-styling-title"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {props.modalTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.modalBody}
            </Modal.Body>
        </Modal>
    )
}
