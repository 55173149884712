//Import React Library
import React from 'react'

//Import state management provider
import { BooksProvider } from "./contextBooks"

//Import Categories Layout
import Books from "./Books"
export default function Index() {

    return (
        <BooksProvider>
            <Books/>
        </BooksProvider>
    )
}
