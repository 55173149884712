//Import React Library
import React from 'react'

//Import state management provider
import { ServicesProvider } from "./contextServices"

//Import Categories Layout
import Services from "./Services"
export default function Index() {

    return (
        <ServicesProvider>
            <Services/>
        </ServicesProvider>
    )
}

