//Import React Library
import React from 'react'
//Import dropdown reusable component
import DropdownInput from "../../Reusable/Filters/DropdownInput"

//Import text input
import TextInput from "../../Reusable/Filters/TextInput"

//A custom title
import { SectionTitle } from "@lexis/ui-library"

// Table with books data
import Table from "./TableSubcategorias"

//Impor Modal with from to create and edit category
import Modal from "../../Reusable/Modal/GenericModal"
import ModalBody from "./ModalBodySubcategories"

// Import context
import { useSubcategoriesContext } from "./contextSubcategories"
import { useGlobalContext } from "../../../Context/context"

export default function Subcategories() {
    const { textInput, setTextInput, visibleModal, modalTitle, setVisibleModal, selectedCategoria, setSelectedCategoria } = useSubcategoriesContext()
    const {categorias}=useGlobalContext()
    return (

        <div className="col-12">
            <SectionTitle color="var(--primaryText)" title="Administrador de Sub-categorías" />
            <div className="row mx-0 w-100 mb-4">
                <div className="col-12 col-md-6">
                    <TextInput placeholder="Ingrese el nombre de la categoría o sub-categoría" value={textInput} handleChange={setTextInput} />
                </div>
                <div className="col-12 col-md-6 my-auto">
                    <DropdownInput
                        placeholder="Categoría"
                        value={selectedCategoria}
                        handleChange={e => setSelectedCategoria(e)}
                        options={[{ value: null, label: "Todas" }, ...categorias]} />
                </div>

            </div>


            <Table />
            <Modal
                show={visibleModal}
                onHide={() => setVisibleModal(false)}
                modalTitle={modalTitle}
                modalBody={<ModalBody />}
            />

        </div>

    )
}
