//Import React Library
import React, { useState } from 'react'

//Load states from state management provider
import { useAccessTypeContext } from "./contextAccessType"
import { useGlobalContext } from "../../../Context/context"

//Import components from Lexis UI Library
import { Alert, makeAnimated, Select } from "@lexis/ui-library"

//Import fucntion from LHL admin api component
import { saveAccess, updateAccess, findAccessById } from "@lexis/admin-api"

//Import Dropzone file picker
import Dropzone from "../../Reusable/Filters/Dropzone"

//define helpers
const animatedComponents = makeAnimated();

export default function ModalCategories(props) {
    const { action, idAccessType } = useAccessTypeContext();
    const { fetchTiposAcceso, tipoAccesos } = useGlobalContext();
    //Define states    
    const [formAccesType, setFormAccessType] = useState(null)   
    const [alertMessage, setAlertMessage] = useState("")
    const [alertVariant, setAlertVariant] = useState("")
    const [visible, setVisible] = useState(false);


    React.useEffect(() => {
        console.log("idAccessType:", idAccessType)

        if ((action === "edit") & (idAccessType !== null)) {
            findAccessById(idAccessType).then((data) => {
                console.log("find access type: ", data)
                let dato = data.data;
                setFormAccessType(dato.tipoAcceso)
                
            })
        }
    }, [idAccessType, action])

    React.useEffect(() => {
        console.log("props.action:", action)
        if (action === "add") {

            resetFormValues()

        }
    }, [action])

    const resetFormValues = () => {
        setFormAccessType(null)
        setVisible(false)
        

    }



    const handleSubmitAction = () => {
        

        if (action === "add") {
            saveAccess(formAccesType).then((data) => {

                console.log("save access type: ", data)
                handleApiResponse(data)
            })
        }
        else {
            updateAccess(idAccessType, formAccesType).then((data) => {
                console.log("edit acces type: ", data)
                handleApiResponse(data)
            })
        }
    }

    const handleApiResponse = (data) => {
        if (data.state === "SUCCESS") {
            setAlertMessage("Proceso Exitoso!")
            setAlertVariant("success")
            setVisible(true)
            //resetFormValues()
            fetchTiposAcceso()
        }
        else {
            let errorMsg = ""
            if (data.error === 400) { errorMsg = "Por favor llene todos los campos requeridos" }
            setAlertMessage("Error: " + errorMsg)
            setAlertVariant("danger")
            setVisible(true)
        }
    }

   
    

    return (
        <div className="row mx-0 w-100">


            <div className="col-12 py-2">
                <label for="ftitulo" className="form-label">Nombre *</label>
                <input type="text" className="form-input px-4" id="ftitulo" name="ftitulo" onChange={e => setFormAccessType(e.target.value)} value={formAccesType} />
            </div>

            

            <button className="btn my-button mx-auto my-3" onClick={() => handleSubmitAction()}>{action === "add" ? "Añadir Tipo de Acceso" : "Editar Tipo de Acceso"}</button>


            {
                visible && <Alert variant={alertVariant} onClose={() => setVisible(false)} dismissible className="w-100">
                    <p>
                        {alertMessage}
                    </p>
                </Alert>
            }
        </div >
    )

}
