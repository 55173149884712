import React from 'react'

//Import components from Lexis UI Library
import { Dropzone } from "@lexis/ui-library"


export default function MyDropzone(props) {

    const getUploadParams = () => {
        return { url: 'https://httpbin.org/post' }
    }



    return (
        <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={props.handleChangeStatus}
            maxFiles={1}
            multiple={false}
            //onSubmit={handleSubmit}                          
            inputContent={props.placeholder}
            //accept="image/*"
            styles={{
                dropzone: { width: 400, height: 100 },
                dropzoneActive: { borderColor: "green" }
            }}
            accept={props.accept}
            maxSizeBytes={1024*props.maxSizeBytes}
        />
    )
}
