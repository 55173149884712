import React, { useState, useContext } from 'react'
import { lhlCategories, lhlSubcategories, lhlMaterias, lhlFindPaises, lhlFindLibros } from "@lexis/admin-api"

//Import global context
import { useGlobalContext } from "../../../Context/context"
// make a new context
export const SubcategoriesContext = React.createContext();
export const useSubcategoriesContext = () => useContext(SubcategoriesContext);

// create the provider
export const SubcategoriesProvider = (props) => {
    const { categorias, subcategorias } = useGlobalContext()
    const [filteredSubcategorias, setFilteredSubcategorias] = useState(null)
    const [textInput, setTextInput] = useState(null)
    const [visibleModal, setVisibleModal] = useState(false)
    const [modalTitle, setModalTitle] = useState(false)
    const [action, setAction] = useState("add")
    const [idSubcategoria, setidSubcategoria] = useState(null)
    const [selectedCategoria, setSelectedCategoria] = useState({value:null,label:"Todas las categorías"})

    React.useEffect(() => {
        setFilteredSubcategorias(subcategorias)
    }, [subcategorias])

    React.useEffect(() => {
        let temp;
        console.log("selectedCategoria", selectedCategoria)
        console.log("categorias", categorias)
        console.log("filteredSubcategorias", filteredSubcategorias)
        console.log("textInput", textInput)
        console.log("subcategorias", subcategorias)
        if (categorias && subcategorias) {
           
            temp = textInput ? subcategorias.filter(subcategoria => (subcategoria.subcategoria?.toLowerCase().includes(textInput.toLowerCase()) || subcategoria.categoria.categoria?.toLowerCase().includes(textInput.toLowerCase()))) : subcategorias
            temp = selectedCategoria.value ? temp.filter(subcategoria => subcategoria.categoria === selectedCategoria.label) : temp
        }
        else {
            temp = subcategorias
        }

        setFilteredSubcategorias(temp)
    }, [textInput, selectedCategoria])


    const handleTextInputChange = (value) => {
        setTextInput(value)
        console.log("Text Input:", value)
    }


    return (
        <SubcategoriesContext.Provider value={{
            filteredSubcategorias, setFilteredSubcategorias,
            textInput, setTextInput,
            handleTextInputChange,
            visibleModal, setVisibleModal,
            modalTitle, setModalTitle,
            action, setAction,
            selectedCategoria, setSelectedCategoria,
            idSubcategoria, setidSubcategoria
        }}>
            {props.children}
        </SubcategoriesContext.Provider>
    )
}


