//Import React Library
import React from 'react'
//Import dropdown reusable component
import DropdownInput from "../../Reusable/Filters/DropdownInput"

//Import text input
import TextInput from "../../Reusable/Filters/TextInput"

//A custom title
import { SectionTitle } from "@lexis/ui-library"

// Table with books data
import Table from "./TableSubjects"

//Impor Modal with from to create and edit category
import Modal from "../../Reusable/Modal/GenericModal"
import ModalBody from "./ModalBodySubjects"

// Import context
import { useSubjectsContext } from "./contextSubjects"
import { useGlobalContext } from "../../../Context/context"

export default function Subjects() {
    const { textInput, setTextInput, visibleModal, modalTitle, setVisibleModal, selectedCategoria, setSelectedCategoria,selectedSubcategoria, setSelectedSubcategoria } = useSubjectsContext()
    const {categorias, subcategorias}=useGlobalContext()
    return (

        <div className="col-12">
            <SectionTitle color="var(--primaryText)" title="Administrador de Materias" />
            <div className="row mx-0 w-100 mb-4">
                <div className="col-12 px-0">
                    <TextInput placeholder="Ingrese el nombre de la categoría, sub-categoría o materia" value={textInput} handleChange={setTextInput} />
                </div>
                <div className="col-12 col-md-6 my-auto">
                    <DropdownInput
                        placeholder="Categoría"
                        value={selectedCategoria}
                        handleChange={e => (setSelectedCategoria(e), setSelectedSubcategoria({ value: null, label: "Todas" }))}
                        options={[{ value: null, label: "Todas" }, ...categorias]} />
                </div>

                <div className="col-12 col-md-6 my-auto">
                    <DropdownInput
                        placeholder="Sub-categoría"
                        value={selectedSubcategoria}
                        handleChange={e => setSelectedSubcategoria(e)}
                        options={[{ value: null, label: "Todas" }, ...subcategorias.filter(subcategoria => subcategoria.idCategoria === (selectedCategoria ? selectedCategoria.value : null))]} />
                        
                </div>

            </div>


            <Table />
            <Modal
                show={visibleModal}
                onHide={() => setVisibleModal(false)}
                modalTitle={modalTitle}
                modalBody={<ModalBody />}
            />

        </div>

    )
}
