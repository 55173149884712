//Import React Library
import React, { useState } from 'react'

//Load states from state management provider
import { useServicesContext } from "./contextServices"
import { useGlobalContext } from "../../../Context/context"

//Import components from Lexis UI Library
import { Alert, makeAnimated, Select } from "@lexis/ui-library"

//Import fucntion from LHL admin api component
import { saveService, updateService, findServiceById } from "@lexis/admin-api"



//define helpers
const animatedComponents = makeAnimated();

export default function ModalCategories(props) {
    const { action, idService } = useServicesContext();
    const { fetchServicios, tipoAccesos } = useGlobalContext();
    //Define states    
    const [formAccesType, setFormAccessType] = useState(null)   
    const [formService, setFormService] = useState(null)   
    const [alertMessage, setAlertMessage] = useState("")
    const [alertVariant, setAlertVariant] = useState("")
    const [visible, setVisible] = useState(false);


    React.useEffect(() => {
        console.log("idService:", idService)

        if ((action === "edit") & (idService !== null)) {
            findServiceById(idService).then((data) => {
                console.log("find access type: ", data)
                let dato = data.data;
                setFormService(dato.servicio)
                setFormAccessType(tipoAccesos?.filter(acceso => dato.tipoAcceso.id===acceso.id))
            })
        }
    }, [idService, action])

    React.useEffect(() => {
        console.log("props.action:", action)
        if (action === "add") {

            resetFormValues()

        }
    }, [action])

    const resetFormValues = () => {
        setFormAccessType(null)
        setFormService(null)
        setVisible(false)
        

    }



    const handleSubmitAction = () => {
        

        if (action === "add") {
            saveService(formService,formAccesType.id).then((data) => {

                console.log("save service: ", data)
                handleApiResponse(data)
            })
        }
        else {
            updateService(idService, formService, formAccesType.id).then((data) => {
                console.log("edit service: ", data)
                handleApiResponse(data)
            })
        }
    }

    const handleApiResponse = (data) => {
        if (data.state === "SUCCESS") {
            setAlertMessage("Proceso Exitoso!")
            setAlertVariant("success")
            setVisible(true)
            //resetFormValues()
            fetchServicios()
        }
        else {
            let errorMsg = ""
            if (data.error === 400) { errorMsg = "Por favor llene todos los campos requeridos" }
            setAlertMessage("Error: " + errorMsg)
            setAlertVariant("danger")
            setVisible(true)
        }
    }

   
    

    return (
        <div className="row mx-0 w-100">


            <div className="col-12 py-2">
                <label for="ftitulo" className="form-label">Servicio *</label>
                <input type="text" className="form-input px-4" id="ftitulo" name="ftitulo" onChange={e => setFormService(e.target.value)} value={formService} />
            </div>

            <div className="col-12 py-2">
                <label className="form-label">Tipo de Acceso *</label>
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    placeholder="Tipo de Acceso"
                    value={formAccesType}
                    
                    //defaultValue={categorias?categorias[1]:null}
                    onChange={e => (setFormAccessType(e))}
                    options={tipoAccesos}
                />
            </div>
            

            <button className="btn my-button mx-auto my-3" onClick={() => handleSubmitAction()}>{action === "add" ? "Añadir Servicio" : "Editar Servicio"}</button>


            {
                visible && <Alert variant={alertVariant} onClose={() => setVisible(false)} dismissible className="w-100">
                    <p>
                        {alertMessage}
                    </p>
                </Alert>
            }
        </div >
    )

}
