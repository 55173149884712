//Import React Library
import React, { useState } from 'react'
//Import components from Lexis UI Library
import { CountUpBlock, Link, GiEvilBook, GiChessKing, GiChessQueen, GiChessPawn, LColor, FaUserLock } from "@lexis/ui-library"
//Import state management provider
import { useGlobalContext } from "../../../Context/context"

//Define a custom border style
const borderStyle = "2px solid var(--primaryText)"

// This components render a counter section
export default function Counter() {
    //Load states from state management provider
    const { categorias, subcategorias, materias, libros, setSection, tipoAccesos, servicios } = useGlobalContext();
    //Define states
    const [borders, setBorders] = useState({
        categorias: "none",
        subcategorias: "none",
        materias: "none",
        libros: borderStyle
    })

    //Handles click on "Edit" button setting to visible the desired section
    const handleClick = (id) => {
        setSection(id)        
        setBorders({
            tipoAcceso: id === "TipoAcceso" ? borderStyle : "none",
            servicios: id === "Servicios" ? borderStyle : "none",
            categorias: id === "Categorías" ? borderStyle : "none",
            subcategorias: id === "Sub-categorías" ? borderStyle : "none",
            materias: id === "Materias" ? borderStyle : "none",
            libros: id === "Libros" ? borderStyle : "none"
        })
    }

    
    return (
        <div className="row mx-auto w-100 text-center py-4">
            <div className="col-6 col-md-3 col-xl-2" >
                <div style={{ border: borders.tipoAcceso }} className="mt-3">
                    {tipoAccesos && <CountUpBlock
                        end={tipoAccesos.length}
                        background="rgba(173, 216, 230, 0.4)"
                        border={borders.categorias}
                        title="Tipos de Acceso" icon={<FaUserLock fontSize="80" className="my-3" />}
                        button={<Link className="btn my-button mt-3" to="/access-type" onClick={()=>handleClick("TipoAcceso")}>Editar</Link>} />
                    }
                </div>
            </div>
            <div className="col-6 col-md-3 col-xl-2" >
                <div style={{ border: borders.servicios }} className="mt-3">
                    {servicios && <CountUpBlock
                        end={servicios.length}
                        background="rgba(216, 191, 216, 0.4)"
                        border={borders.servicios}
                        title="Servicios" icon={<LColor width="65" className="my-4" />}
                        button={<Link className="btn my-button mt-3" to="/services" onClick={()=>handleClick("Servicios")}>Editar</Link>} />
                    }
                </div>
            </div>
            <div className="col-6 col-md-3 col-xl-2" >
                <div style={{ border: borders.categorias }} className="mt-3">
                    {categorias && <CountUpBlock
                        end={categorias.length}
                        background="rgba(255, 99, 132, 0.2)"
                        border={borders.categorias}
                        title="Categorías" icon={<GiChessKing fontSize="80" className="my-3" />}
                        button={<Link className="btn my-button mt-3" to="/categories" onClick={()=>handleClick("Categorías")}>Editar</Link>} />
                    }
                </div>
            </div>
            <div className="col-6 col-md-3 col-xl-2">
                <div style={{ border: borders.subcategorias }} className="mt-3">
                    {subcategorias && <CountUpBlock
                        end={subcategorias.length}
                        background="rgba(255, 159, 64, 0.2)"
                        border={borders.subcategorias}
                        title="Sub-categorías"
                        icon={<GiChessQueen fontSize="80" className="my-3" />}
                        button={<Link className="btn my-button mt-3" to="/subcategories" onClick={()=>handleClick("Sub-categorías")}>Editar</Link>}
                    />
                    }
                </div>
            </div>
            <div className="col-6 col-md-3 col-xl-2">
                <div style={{ border: borders.materias }} className="mt-3">
                    {materias && <CountUpBlock end={materias.length}
                        //background="rgba(255, 205, 86, 0.2)"
                        background="rgba(200, 202, 247,0.45)"
                        title="Materias"
                        border={borders.materias}
                        icon={<GiChessPawn fontSize="80" className="my-3" />}
                        button={<Link className="btn my-button mt-3" to="/subjects" onClick={()=>handleClick("Materias")}>Editar</Link>}
                    />}
                </div>
            </div>
            <div className="col-6 col-md-3 col-xl-2">
                <div style={{ border: borders.libros }} className="mt-3">
                    {libros && <CountUpBlock
                        end={libros.length}
                        suffix=""
                        background="rgba(75, 192, 192, 0.2)"
                        border={borders.libros}
                        title="Libros"
                        icon={<GiEvilBook fontSize="80" className="my-3" />}
                        button={<Link className="btn my-button mt-3" to="/books" onClick={()=>handleClick("Libros")}>Editar</Link>}
                    />}
                </div>
            </div>
        </div>
    )
}
