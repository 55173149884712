import React from 'react'
import GenericTable from "../../Reusable/Table/GenericTable"
import { FaPencilAlt, FaTrash, IoMdAdd } from "@lexis/ui-library"
import { useSubjectsContext } from "./contextSubjects"
import { useGlobalContext } from "../../../Context/context"
import { lhlDeleteMaterias } from "@lexis/admin-api"
export default function Table(props) {
    const { setModalTitle, filteredSubjects, setVisibleModal, setAction, setidMateria } = useSubjectsContext()
    const { fetchSubjects, subcategorias } = useGlobalContext()

    const handleDeleteBtnClic = (row) => {
        lhlDeleteMaterias(row.value).then(({ state, error}) => {
            if (state === "ERROR") {

                error.json().then((data) => { alert(`Error: ${data.materia}`) })
            } 
            else {
                fetchSubjects(subcategorias)

            }
        })
    }

    const handleEditClic = (row) => {
        console.log("Edit row:", row)
        setModalTitle("Editar Materia")
        setAction("edit")
        setidMateria(row.value)
        setVisibleModal(true)
    }

    const handleNewClic = (row) => {
        console.log("New row:", row)
        setModalTitle("Añadir Materia")
        setAction("add")
        //setidCategoria(row.value)
        setVisibleModal(true)
    }

    const columns = [
        {
            name: 'Materia',
            selector: 'materia',
            sortable: true,
            grow: 2,
            //cell: row => categorias.filter(categoria => categoria.value === row.idCategoria)[0].label
        }
        ,
        {
            name: 'Categoría',
            selector: 'categoria',
            sortable: true,
            grow: 2,
            cell: row => row.subcategoria.categoria.categoria
        }
        ,
        {
            name: 'Sub-categoría',
            selector: 'subcategoria',
            sortable: true,
            grow: 2,
            cell: row => row.subcategoria.subcategoria
        }
        ,

        {
            name: 'Acciones',
            selector: 'id',
            sortable: false,
            grow: 2,
            cell: row => <div className="row mx-0">
                <button className="btn my-button mr-2" onClick={() => handleEditClic(row)}><FaPencilAlt /></button>
                <button className="btn my-button ml-2" onClick={() => handleDeleteBtnClic(row)}><FaTrash /></button>
            </div>
        }
    ];


    return (

        filteredSubjects ? <GenericTable
            title="Materias"
            columns={columns}
            data={filteredSubjects}
            newBtnLabel={<span><IoMdAdd /> Nueva</span>}
            handleNewClic={handleNewClic}
        /> : <React.Fragment />


    )
}
