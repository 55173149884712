//Import React Library
import React from 'react'

//Import state management provider
import { AccessTypeProvider } from "./contextAccessType"

//Import Categories Layout
import AccessType from "./AccessType"
export default function Index() {

    return (
        <AccessTypeProvider>
            <AccessType/>
        </AccessTypeProvider>
    )
}
