import React from 'react'
import GenericTable from "../../Reusable/Table/GenericTable"
import { FaPencilAlt, FaTrash, IoMdAdd } from "@lexis/ui-library"
import { useAccessTypeContext } from "./contextAccessType"
import { useGlobalContext } from "../../../Context/context"
import { deleteAccess } from "@lexis/admin-api"
//const estados = [{ value: 0, label: "Pública y PRO" }, { value: 1, label: "Solo PRO" }, { value: 3, label: "Solo Pública" }, { value: 2, label: "Deshabilitada" }]
export default function Table(props) {
    const { setModalTitle, filteredAccessTypes, setVisibleModal, setAction, setIdAccessType } = useAccessTypeContext()
    const {  fetchTiposAcceso, tipoAccesos } = useGlobalContext()

    const handleDeleteBtnClic = (row) => {
        deleteAccess(row.value).then(() => {
            //setSelectedCategoria(row.idCategoria)
            fetchTiposAcceso()
        })
    }

    const handleEditClic = (row) => {
        console.log("Edit row:", row)
        setModalTitle("Editar Tipo de Acceso")
        setAction("edit")
        setIdAccessType(row.value)
        setVisibleModal(true)
    }

    const handleNewClic = (row) => {
        console.log("New row:", row)
        setModalTitle("Añadir Tipo de Acceso")
        setAction("add")
        //setidCategoria(row.value)
        setVisibleModal(true)
    }

    const columns = [
        
        {
            name: 'id',
            selector: 'id',
            sortable: true,
            grow: 2,
            //cell: row => categorias.filter(categoria => categoria.value === row.idCategoria)[0].label
        }
        ,
        {
            name: 'Tipo de Acceso',
            selector: 'tipoAcceso',
            sortable: true,
            grow: 4,
            //cell: row => categorias.filter(categoria => categoria.value === row.idCategoria)[0].label
        }
        ,
        {
            name: 'Acciones',
            selector: 'id',
            sortable: false,
            grow: 2,
            cell: row => <div className="row mx-0">
                <button className="btn my-button mr-2" onClick={() => handleEditClic(row)}><FaPencilAlt /></button>
                <button className="btn my-button ml-2" onClick={() => handleDeleteBtnClic(row)}><FaTrash /></button>
            </div>
        }
    ];


    return (
       
        filteredAccessTypes?<GenericTable
                title="Tipos de Acceso"
                columns={columns}
                data={filteredAccessTypes}     
                newBtnLabel={<span><IoMdAdd/> Nuevo</span>}  
                handleNewClic={handleNewClic}         
            />:<React.Fragment/>

        
    )
}
