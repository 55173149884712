import React from 'react'
import GenericTable from "../../Reusable/Table/GenericTable"
import { FaPencilAlt, FaTrash, IoMdAdd } from "@lexis/ui-library"
import { useCategoriesContext } from "./contextCategories"
import { useGlobalContext } from "../../../Context/context"
import { lhlDeleteCategory } from "@lexis/admin-api"
//const estados = [{ value: 0, label: "Pública y PRO" }, { value: 1, label: "Solo PRO" }, { value: 3, label: "Solo Pública" }, { value: 2, label: "Deshabilitada" }]
export default function Table(props) {
    const { setModalTitle, filteredCategorias, setVisibleModal, setAction, setidCategoria } = useCategoriesContext()
    const { fetchCategorias, tipoAccesos } = useGlobalContext()

    const handleDeleteBtnClic = (row) => {
        lhlDeleteCategory(row.value).then(({ state, error }) => {
            if (state === "ERROR") {
                
                error.json().then((data) => { alert(`Error: ${data.categoria}`) })
            }
            else{
                fetchCategorias()

            }
        })
    }

    const handleEditClic = (row) => {
        console.log("Edit row:", row)
        setModalTitle("Editar Categoría")
        setAction("edit")
        setidCategoria(row.value)
        setVisibleModal(true)
    }

    const handleNewClic = (row) => {
        console.log("New row:", row)
        setModalTitle("Añadir Categoría")
        setAction("add")
        //setidCategoria(row.value)
        setVisibleModal(true)
    }

    const columns = [

        {
            name: 'Categoría',
            selector: 'categoria',
            sortable: true,
            grow: 2,
            //cell: row => categorias.filter(categoria => categoria.value === row.idCategoria)[0].label
        }
        ,
        {
            name: 'Tooltip',
            selector: 'tooltip',
            sortable: true,
            grow: 4,
            //cell: row => categorias.filter(categoria => categoria.value === row.idCategoria)[0].label
        }
        ,
        {
            name: 'Acceso',
            selector: 'pro',
            sortable: true,
            grow: 3,
            cell: row => <div>{row.listaTipoAccesos.map(item => <span>{tipoAccesos?.filter(acceso => acceso.id === item.id)[0].tipoAcceso} </span>)}</div>
        }
        ,

        {
            name: 'Link',
            selector: 'link',
            sortable: true,
            grow: 2
        },
        {
            name: 'Ícono',
            selector: 'icon',
            sortable: true,
            grow: 2,
            cell: row => row.iconName ? <img src={row.iconName + "," + row.icon} className="img-fluid w-25"></img> : "-"
        },
        {
            name: 'Acciones',
            selector: 'id',
            sortable: false,
            grow: 2,
            cell: row => <div className="row mx-0">
                <button className="btn my-button mr-2" onClick={() => handleEditClic(row)}><FaPencilAlt /></button>
                <button className="btn my-button ml-2" onClick={() => handleDeleteBtnClic(row)}><FaTrash /></button>
            </div>
        }
    ];


    return (

        filteredCategorias ? <GenericTable
            title="Categorías"
            columns={columns}
            data={filteredCategorias}
            newBtnLabel={<span><IoMdAdd /> Nueva</span>}
            handleNewClic={handleNewClic}
        /> : <React.Fragment />


    )
}
