import React, { useState, useContext } from 'react'
import { lhlCategories, lhlSubcategories, lhlMaterias, lhlFindPaises, lhlFindLibros, lhlFindAllLibros, apiStates, findAllAccessTypes, findAllServices } from "@lexis/admin-api"
// make a new context
export const GlobalContext = React.createContext();
export const useGlobalContext = () => useContext(GlobalContext);

// create the provider
export const GlobalProvider = (props) => {
    const [tipoAccesos, setTipoAccesos] = useState(null)
    const [servicios, setServicios] = useState(null)
    const [categorias, setCategorias] = useState(null)
    const [subcategorias, setSubcategorias] = useState(null)
    const [materias, setMaterias] = useState(null)
    const [paises, setPaises] = useState(null)
    const [libros, setLibros,] = useState(null)
    const [section, setSection] = useState("Libros")

    React.useEffect(() => {
        !tipoAccesos && fetchTiposAcceso()
    }, [])

    const fetchTiposAcceso=()=>{
        findAllAccessTypes().then(({ state, data }) => {
            console.log("tipoaccesos:", state)
            if(state===apiStates.SUCCESS){
                let temp=[];
                data.forEach((element, i) => {
                    temp[i] = { value: element.id, label: element.tipoAcceso, ...element }
                });
                setTipoAccesos(temp)
                console.log("tipoaccesos:", temp)
            }
            else{
                setTipoAccesos(null)
            }

        })
    }
    React.useEffect(() => {
        !servicios && fetchServicios()
    }, [])

    const fetchServicios=()=>{
        findAllServices().then(({ state, data }) => {
            if(state===apiStates.SUCCESS){
                setServicios(data)
            }
            else{
                setServicios(null)
            }
        })
    }

    React.useEffect(() => {

        !categorias && fetchCategorias().then((tempCategories) => {

        })



        !paises && lhlFindPaises().then((data) => {
            //console.log("catalogo paises:", data)
            let temp = []
            data.data.forEach((element, i) => {
                temp[i] = { value: element.id, label: element.pais }
            });
            console.log("catalogo paises:", temp)
            setPaises(temp)
        }, []);
    }, [])

    React.useEffect(() => {
        !subcategorias && categorias && fetchSubcategories(categorias).then(() => {

        })
    }, [categorias])

    React.useEffect(() => {
        materias && fetchBooks().then(() => {

        })
    }, [materias])

    React.useEffect(() => {
        !materias && subcategorias && fetchSubjects(subcategorias).then(() => {

        })
    }, [subcategorias])


    const fetchCategorias = async () => {
        console.log("fetching categories")
        let temp = []
        lhlCategories().then((data) => {

            console.log("find categorias:", data)

            data.data.forEach((element, i) => {
                temp[i] = { value: element.id, label: element.categoria, ...element }
            });
            //setSubcat(temp)
            setCategorias(temp)

        })
        return temp;
    }

    const fetchSubcategories = async (tempCategories) => {
        let temp = []
        lhlSubcategories().then((data) => {
            console.log("tempCategories: ", tempCategories)
            console.log("tempsubCategories: ", data.data)
            data.data.forEach((element, i) => {
                temp[i] = { value: element.id, label: element.subcategoria, ...element }
            });
            console.log("catalogo subcategorias mod:", temp)
            setSubcategorias(temp)

        });

        return temp;
    }

    const fetchSubjects = async (tempSubcategories) => {
        let temp = []
        lhlMaterias().then((data) => {
            console.log("tempSubcategories: ", tempSubcategories)
            data.data.forEach((element, i) => {

                console.log("element: ", element)
                /*console.log("tempSubcategories: ", tempSubcategories)
                console.log("element: ", element)
                console.log("element: ", element.idSubcategoria)*/
                //let tempFilter = tempSubcategories.filter(subcategoria => subcategoria.id == element.idSubcategoria)
                //console.log("tempFilter: ", tempFilter)
                temp[i] = {
                    value: element.id, label: element.materia, ...element
                }
            });
            console.log("catalogo materias:", temp)
            setMaterias(temp)

        });
    }

    const fetchBooks = async () => {
        /*let temp = []
        categorias.map(categoria => !categoria.link && lhlFindLibros(categoria.id).then((data) => {
            console.log("find libros:", data)
            
            temp = [...temp, ...data.data]
            setLibros(temp)
            console.log("libros: ", temp)
        })

        )*/
        lhlFindAllLibros().then((data) => {
            setLibros(data.data)
        })



    }
    return (
        <GlobalContext.Provider value={{
            paises,
            categorias, setCategorias,
            subcategorias, setSubcategorias,
            materias, setMaterias,
            libros, setLibros,
            tipoAccesos, setTipoAccesos,
            servicios, setServicios,
            section, setSection,
            fetchCategorias, fetchSubcategories, fetchSubjects, fetchBooks, fetchTiposAcceso, fetchServicios
        }}>
            {props.children}
        </GlobalContext.Provider>
    )
}


