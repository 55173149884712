//Import React Library
import React, { useState } from 'react'

//Load states from state management provider
import { useCategoriesContext } from "./contextCategories"
import { useGlobalContext } from "../../../Context/context"

//Import components from Lexis UI Library
import { Alert, makeAnimated, Select } from "@lexis/ui-library"

//Import fucntion from LHL admin api component
import { lhlSaveCategory, lhlUpdateCategory, lhlFindCategory } from "@lexis/admin-api"

//Import Dropzone file picker
import Dropzone from "../../Reusable/Filters/Dropzone"

//define helpers
const animatedComponents = makeAnimated();

export default function ModalCategories(props) {
    const { action, idCategoria } = useCategoriesContext();
    const { fetchCategorias, tipoAccesos } = useGlobalContext();
    //Define states
    const [formTooltip, setFormTooltip] = useState(null)
    const [formLink, setFormLink] = useState(null)
    const [formTiposAcceso, setFormTiposAcceso] = useState(null)

    const [formTitulo, setFormTitulo] = useState(null)
    const [formIcon, setFormIcon] = useState(null)
    const [formIconName, setFormIconName] = useState(null)
    const [alertMessage, setAlertMessage] = useState("")
    const [alertVariant, setAlertVariant] = useState("")
    const [visible, setVisible] = useState(false);


    React.useEffect(() => {
        console.log("idCategoria:", idCategoria)

        if ((action === "edit") & (idCategoria !== null)) {
            lhlFindCategory(idCategoria).then((data) => {
                console.log("find categoria: ", data)
                let dato = data?.data[0];
                setFormLink(dato.link)
                setFormTitulo(dato.categoria)
                setFormTooltip(dato.tooltip)
                setFormIcon(dato.icon)
                setFormIconName(dato.iconName)
                //setFormTiposAcceso(dato.listaTipoAccesos.map(item=>tipoAccesos?.filter(acceso=> acceso.id===item.id)))
                let tipoAccessosId=[]
                dato.listaTipoAccesos.forEach(item=>tipoAccessosId.push(item.id))
                setFormTiposAcceso(tipoAccesos?.filter(acceso => tipoAccessosId.includes(acceso.id)))
            })
        }
    }, [idCategoria, action])

    React.useEffect(() => {
        console.log("props.action:", action)
        if (action === "add") {

            resetFormValues()

        }
    }, [action])

    const resetFormValues = () => {
        setFormTooltip("")
        setFormTitulo("")
        setAlertMessage("")
        setAlertVariant("")
        setFormLink("")
        setVisible(false)
        setFormIcon(null)
        setFormIconName(null)
        setFormTiposAcceso(null)

    }



    const handleSubmitAction = () => {
        console.log("action: ", action)
        console.log("formTiposAcceso: ", formTiposAcceso)
        let tempTiposAcceso = [];
        formTiposAcceso?.forEach((acceso) => {
            tempTiposAcceso.push(acceso.id)
        });

        if (action === "add") {
            lhlSaveCategory(formTitulo, formTooltip, formIcon, formIconName, formLink, null, tempTiposAcceso).then((data) => {

                console.log("save categoria: ", data)
                handleApiResponse(data)
            })
        }
        else {
            lhlUpdateCategory(idCategoria, formTitulo, formTooltip, formIcon, formIconName, formLink, null, tempTiposAcceso).then((data) => {
                console.log("edit categoria: ", data)
                handleApiResponse(data)
            })
        }
    }

    const handleApiResponse = (data) => {
        if (data.state === "SUCCESS") {
            setAlertMessage("Proceso Exitoso!")
            setAlertVariant("success")
            setVisible(true)
            //resetFormValues()
            fetchCategorias()
        }
        else {
            let errorMsg = ""
            if (data.error === 400) { errorMsg = "Por favor llene todos los campos requeridos" }
            setAlertMessage("Error: " + errorMsg)
            setAlertVariant("danger")
            setVisible(true)
        }
    }

    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status) => {
        console.log("status: ", status);
        console.log("meta: ", meta);
        console.log("file: ", file);
        convertFileToBase64(file)
        //console.log("props.action:", action)
        //action === "add" && setFormArchivo(file.name)
        //setFormFile(file)
    };

    const convertFileToBase64 = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () =>
                resolve({
                    fileName: file.title,
                    base641: reader.result
                });
            reader.onerror = reject;
            reader.onloadend = function () {
                let temp = reader.result.split(",")
                console.log("reader: ", temp);
                setFormIcon(temp[1])
                setFormIconName(temp[0])
            };
        });

    return (
        <div className="row mx-0 w-100">


            <div className="col-12 py-2">
                <label for="ftitulo" className="form-label">Nombre *</label>
                <input type="text" className="form-input px-4" id="ftitulo" name="ftitulo" onChange={e => setFormTitulo(e.target.value)} value={formTitulo} />
            </div>

            <div className="col-12 py-2">
                <label for="ftooltip" className="form-label">Tooltip *</label>
                <input type="text" className="form-input px-4" id="ftooltip" name="ftooltip" onChange={e => setFormTooltip(e.target.value)} value={formTooltip} />
            </div>

            <div className="col-12 py-2">
                <label for="flink" className="form-label">Link</label>
                <input type="text" className="form-input px-4" id="flink" name="flink" onChange={e => setFormLink(e.target.value)} value={formLink} />
            </div>

            <div className="col-12 py-2">
                <label className="form-label"> Tipo de Acceso *</label>
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    placeholder="Tipo de Acceso"
                    value={formTiposAcceso}
                    isMulti
                    //defaultValue={categorias?categorias[1]:null}
                    onChange={e => (setFormTiposAcceso(e))}
                    options={tipoAccesos}
                />
            </div>



            <div className="col-12 py-2">
                <Dropzone
                    handleChangeStatus={handleChangeStatus}
                    placeholder="Seleccione un ícono (max. 512kb)"
                    accept="image/*"
                    maxSizeBytes={512}
                />
            </div>


            <button className="btn my-button mx-auto my-3" onClick={() => handleSubmitAction()}>{action === "add" ? "Añadir Categoría" : "Editar Categoría"}</button>


            {
                visible && <Alert variant={alertVariant} onClose={() => setVisible(false)} dismissible className="w-100">
                    <p>
                        {alertMessage}
                    </p>
                </Alert>
            }
        </div >
    )

}
