import React, { useState, useContext } from 'react'
import { lhlCategories, lhlSubcategories, lhlMaterias, lhlFindPaises, lhlFindLibros } from "@lexis/admin-api"

//Import global context
import { useGlobalContext } from "../../../Context/context"
// make a new context
export const ServicesContext = React.createContext();
export const useServicesContext = () => useContext(ServicesContext);

// create the provider
export const ServicesProvider = (props) => {
    const { tipoAccesos, servicios } = useGlobalContext()
    const [filteredServices, setFilteredServices] = useState(null)
    const [textInput, setTextInput] = useState(null)
    const [visibleModal, setVisibleModal] = useState(false)
    const [modalTitle, setModalTitle] = useState(false)
    const [action, setAction] = useState("add")
    const [idService, setIdService] = useState(null)
    

    React.useEffect(() => {
        setFilteredServices(servicios)
    }, [servicios])

    React.useEffect(() => {
        let temp = servicios && textInput ? servicios.filter(servicio => servicio.servicio.toLowerCase().includes(textInput.toLowerCase())):servicios
        console.log("🚀 ~ file: contextAccessType.js ~ line 27 ~ React.useEffect ~ temp", temp)
        console.log("🚀 ~ file: contextAccessType.js ~ line 27 ~ React.useEffect ~ temp", servicios)
        
        setFilteredServices(temp)
    }, [textInput])

    const handleTextInputChange = (value) => {
        setTextInput(value)
        console.log("Text Input:", value)
    }


    return (
        <ServicesContext.Provider value={{
            filteredServices, setFilteredServices,
            textInput, setTextInput,
            handleTextInputChange,
            visibleModal, setVisibleModal,
            modalTitle, setModalTitle,
            action, setAction,
            idService, setIdService
        }}>
            {props.children}
        </ServicesContext.Provider>
    )
}


