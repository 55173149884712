//Import React Library
import React, { useState } from 'react'

//Load states from state management provider
import { useBooksContext } from "./contextBooks"
import { useGlobalContext } from "../../../Context/context"

//Import components from Lexis UI Library
import { Alert, makeAnimated, Select } from "@lexis/ui-library"

//Import fucntion from LHL admin api component
import { lhlDownloadLibro, s3UploadLibro, s3DeleteLibro, lhlSaveLibro, lhlEditLibro } from "@lexis/admin-api"

//Import Dropzone file picker
import Dropzone from "../../Reusable/Filters/Dropzone"

//define helpers
const animatedComponents = makeAnimated();


export default function ModalSubjects(props) {
    const { action, libroId } = useBooksContext();
    const { fetchBooks, categorias, subcategorias, paises, materias } = useGlobalContext();
    //Define states  

    const [formCategoria, setFormCategoria] = useState(null)
    const [formSubcategoria, setFormSubcategoria] = useState(null)
    const [formMateria, setFormMateria] = useState(null)
    const [formPais, setFormPais] = useState(null)
    const [formTitulo, setFormTitulo] = useState(null)
    const [formAutor, setFormAutor] = useState(null)
    const [formAnio, setFormAnio] = useState(null)
    const [formTema, setFormTema] = useState(null)
    const [formContenido, setFormContenido] = useState(null)
    const [formEditorial, setFormEditorial] = useState(null)
    const [formArchivo, setFormArchivo] = useState(null)
    const [formFile, setFormFile] = useState(null)
    const [s3Path, setS3Path] = useState(null)
    const [alertMessage, setAlertMessage] = useState("")
    const [alertVariant, setAlertVariant] = useState("")
    const [visible, setVisible] = useState(false);




    React.useEffect(() => {

        if ((action === "edit") & (libroId !== null)) {
            lhlDownloadLibro(libroId).then((data) => {
                console.log("download libro: ", data)
                setFormCategoria({ value: data.data.idCategoria, label: categorias.filter(categoria => categoria.id === data.data.idCategoria)[0].label })
                setFormSubcategoria(data.data.idSubcategoria ? { value: data.data.idSubcategoria, label: subcategorias.filter(subcategoria => subcategoria.value === data.data.idSubcategoria)[0].label } : null)
                setFormMateria(data.data.idMateria ? { value: data.data.idMateria, label: materias.filter(materia => materia.value === data.data.idMateria)[0].label } : null)
                setFormPais(data.data.idPais ? { value: data.data.idPais, label: paises.filter(pais => pais.value === data.data.idPais)[0].label } : null)
                setFormTitulo(data.data.titulo)
                setFormArchivo(data.data.archivo)
                setFormAutor(data.data.autor)
                setFormAnio(data.data.anio)
                setFormTema(data.data.tema)
                setFormEditorial(data.data.editorial)
                setFormContenido(data.data.contenido)
                setS3Path(data.data.s3Path)
                setFormFile(null)

            })
        }
    }, [libroId, action])

    React.useEffect(() => {

        if (action === "add") {

            resetFormValues()

        }
    }, [action])

    const resetFormValues = () => {
        setFormCategoria("")
        setFormSubcategoria("")
        setFormMateria("")
        setFormPais("")
        setFormTitulo("")
        setFormArchivo("")
        setFormAutor("")
        setFormAnio("")
        setFormTema("")
        setFormEditorial("")
        setAlertMessage("")
        setAlertVariant("")
        setVisible(false)
        setFormContenido(null)
        setFormFile(null)
        setS3Path(null)

    }



    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status) => {
        console.log("status: ", status);
        console.log("meta: ", meta);
        console.log("file: ", file);
        console.log("props.action:", action)
        action === "add" && setFormArchivo(file.name)
        setFormFile(file)
    };

    /*const convertFileToBase64 = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () =>
                resolve({
                    fileName: file.title,
                    base641: reader.result
                });
            reader.onerror = reject;
            reader.onloadend = function () {

                console.log("reader: ", reader.result.split(","));

                icon = reader.result.split(",");
            };
        });*/

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (files, allFiles) => {
        console.log(files.map(f => f.meta));
        allFiles.forEach(f => f.remove());
    };

    const handleSubmitAction = () => {
        let temps3Path = "";
        if (s3Path) {
            temps3Path = s3Path;
        }
        else {
            temps3Path = formCategoria && formCategoria.label
            temps3Path = formSubcategoria ? temps3Path + "/" + formSubcategoria.label : temps3Path
            temps3Path = formMateria ? temps3Path + "/" + formMateria.label : temps3Path
        }




        setAlertMessage("Procesando Información. Por favor espere.")
        setAlertVariant("primary")
        setVisible(true)
        if ((action === "add") && (formCategoria && formTitulo && formFile)) {
            s3UploadLibro(formFile, temps3Path).then((data) => {
                console.log("data:", data)
                lhlSaveLibro(formCategoria ? formCategoria.value : null, formSubcategoria ? formSubcategoria.value : null, formMateria ? formMateria.value : null, formPais ? formPais.value : null, formTitulo, temps3Path, formArchivo, formAutor, formAnio, formTema, formEditorial).then((data) => {
                    console.log("save libro: ", data)
                    handleApiResponse(data)
                })
            })
        }
        else if ((action === "edit") && (formCategoria && formTitulo)) {
            if (formFile !== null) {
                s3DeleteLibro(temps3Path + "/" + formArchivo).then((data) => {
                    console.log("delete libro:", data)
                    s3UploadLibro(formFile, temps3Path).then((data) => {
                        console.log("upload libro:", data)
                        handleApiResponse(data)
                    })

                })
            }


            lhlEditLibro(libroId, formCategoria ? formCategoria.value : null, formSubcategoria ? formSubcategoria.value : null, formMateria ? formMateria.value : null, formPais ? formPais.value : null, formTitulo, temps3Path, formFile ? formFile.name : formArchivo, formAutor, formAnio, formTema, formEditorial).then((data) => {
                console.log("edit libro: ", data)
                handleApiResponse(data)
            })

        }
        else {
            setAlertMessage("Error: Por favor llene todos los campos requeridos")
            setAlertVariant("danger")
            setVisible(true)
        }


    }

    const handleApiResponse = (data) => {
        if (data.state === "SUCCESS") {
            setAlertMessage("Proceso Exitoso!")
            setAlertVariant("success")
            setVisible(true)
            //resetFormValues()
            fetchBooks()
        }
        else {
            let errorMsg = ""
            if (data.error === 400) { errorMsg = "Por favor llene todos los campos requeridos" }
            setAlertMessage("Error: " + errorMsg)
            setAlertVariant("danger")
            setVisible(true)
        }
    }
    return (
        <div className="row mx-0 w-100">

            {action === "edit" && <div className="col-12 py-2">
                <label for="ftitulo" className="form-label">Nombre del Archivo</label>
                <input type="text" disabled className="form-input px-4" id="ftitulo" name="ftitulo" value={formArchivo} />
            </div>}
            <div className="col-12 py-2">
                <label for="ftitulo" className="form-label">Título *</label>
                <input type="text" className="form-input px-4" id="ftitulo" name="ftitulo" onChange={e => setFormTitulo(e.target.value)} value={formTitulo} />
            </div>

            <div className="col-12 col-md-6 py-2">
                <label className="form-label">Categoría *</label>
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    placeholder="Categoría"
                    value={formCategoria}
                    //defaultValue={categorias?categorias[1]:null}
                    onChange={e => (setFormCategoria(e), setFormSubcategoria(null), setFormMateria(null))}
                    options={categorias}
                />
            </div>

            <div className="col-12 col-md-6 py-2">
                <label className="form-label">Subcategoría</label>
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    placeholder="Subcategoría"
                    isDisabled={formCategoria ? false : true}
                    value={formSubcategoria}
                    //defaultValue={options[1]}
                    onChange={e => (setFormSubcategoria(e), setFormMateria(null))}
                    options={subcategorias && subcategorias.filter(subcategoria => subcategoria.categoria.id === (formCategoria ? formCategoria.value : null))}
                />
            </div>

            <div className="col-12 col-md-6 py-2">
                <label className="form-label">Materia</label>
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    placeholder="Materia"
                    //isDisabled={formCategoria ? false : true}
                    value={formMateria}
                    //defaultValue={options[1]}
                    onChange={e => setFormMateria(e)}
                    options={materias && materias.filter(materia => materia.subcategoria.id === (formSubcategoria ? formSubcategoria.value : null))}
                />
            </div>

            <div className="col-12 col-md-6 py-2">
                <label className="form-label">País</label>
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    placeholder="País"
                    //isDisabled={formCategoria ? false : true}
                    value={formPais}
                    //defaultValue={options[1]}
                    onChange={e => setFormPais(e)}
                    options={paises}
                />
            </div>

            <div className="col-12 col-md-6 py-2">
                <label for="fautor" className="form-label">Autor</label>
                <input type="text" className="form-input px-4" id="fautor" name="fautor" onChange={e => setFormAutor(e.target.value)} value={formAutor} />
            </div>


            <div className="col-12 col-md-6 py-2">
                <label for="fanio" className="form-label">Año</label>
                <input type="text" className="form-input px-4" id="fanio" name="fanio" onChange={e => setFormAnio(e.target.value)} value={formAnio} />
            </div>

            <div className="col-12 py-2">
                <label for="feditorial" className="form-label">Editorial</label>
                <input type="text" className="form-input px-4" id="feditorial" name="feditorial" onChange={e => setFormEditorial(e.target.value)} value={formEditorial} />
            </div>

            <div className="col-12 py-2">
                <label for="ftema" className="form-label">Tema</label>
                <textarea type="text" className="form-input px-4" id="ftema" name="ftema" rows="10" onChange={e => setFormTema(e.target.value)} value={formTema} />
            </div>            

            <div className="col-12 py-2">
                <label className="form-label">Archivo *</label>
                <Dropzone
                    handleChangeStatus={handleChangeStatus}
                    placeholder="Seleccione un archivo"
                    maxSizeBytes={1024 * 200}
                />
            </div>

            <button className="btn my-button mx-auto my-3" onClick={() => handleSubmitAction(action)}>{action === "add" ? "Añadir Ficha" : "Editar Ficha"}</button>


            {visible && <Alert variant={alertVariant} onClose={() => setVisible(false)} dismissible className="w-100">
                <p>
                    {alertMessage}
                </p>
            </Alert>}
        </div >
    )

}
