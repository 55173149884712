import React from 'react'
//import react-router
import { Switch, Route, BrowserRouter } from "@lexis/ui-library";

//import layouts
import Categories from "../Layouts/Categories/index"
import Subcategories from "../Layouts/Subcategories/index"
import Subjects from "../Layouts/Subjects/index"
import Books from "../Layouts/Books/index"
import AccessType  from "../Layouts/AccessType/index"
import Services from "../Layouts/Services/index"
export default function Router() {
    return (

        <Switch>
            <Route exact path="/" component={Books} />
            <Route path="/categories" component={Categories} />
            <Route path="/subcategories" component={Subcategories} />
            <Route path="/subjects" component={Subjects} />
            <Route path="/books" component={Books} />
            <Route path="/services" component={Services} />
            <Route path="/access-type" component={AccessType} />
            <Route component={Books} />
        </Switch>

    )
}
