import React from 'react'
import GenericTable from "../../Reusable/Table/GenericTable"
import { FaPencilAlt, FaTrash, IoMdAdd, FaBookReader, FaShareAlt, CopyToClipboard } from "@lexis/ui-library"
import { useBooksContext } from "./contextBooks"
import { useGlobalContext } from "../../../Context/context"
import { lhlDeleteLibro, lhlDownloadLibro } from "@lexis/admin-api"
export default function Table(props) {
    const { setModalTitle, filteredBooks, setVisibleModal, setAction, setLibroId } = useBooksContext()
    const { fetchBooks, subcategorias, categorias, materias } = useGlobalContext()

    const handleDeleteBtnClic = (row) => {
        lhlDeleteLibro(row.id).then(() => {
            //setSelectedCategoria(row.idCategoria)
            //fetchSubjects(subcategorias)
            fetchBooks()
        })
    }

    const handleEditClic = (row) => {
        console.log("Edit row:", row)
        setModalTitle("Editar Libro")
        setAction("edit")
        setLibroId(row.id)
        setVisibleModal(true)
    }

    const handleNewClic = (row) => {
        console.log("New row:", row)
        setModalTitle("Añadir Libro")
        setAction("add")
        //setidCategoria(row.value)
        setVisibleModal(true)
    }

    const handleVisualizadorBtnClic = (row) => {
        lhlDownloadLibro(row.id).then((data) => {
            console.log("download libro: ", data)

            window.open(data.data.contenido, '_blank');
            //setShowVisualizador(true)

        })
    }

    const handleShareClic = (row) => {

    }

    const columns = [
        {
            name: 'Acciones',
            selector: 'id',
            sortable: false,
            grow: 2,
            cell: row => <div className="d-flex justify-content-between flex-wrap w-100">

                <button className="btn my-button my-2" onClick={() => handleEditClic(row)}><FaPencilAlt /></button>

                <button className="btn my-button my-2" onClick={() => handleDeleteBtnClic(row)}><FaTrash /></button>

                <button className="btn my-button my-2" onClick={() => handleVisualizadorBtnClic(row)}><FaBookReader /></button>

                <CopyToClipboard
                    text={"https://lhl.lexis.com.ec/document?id=" + row.id}
                >
                    <button className="btn my-button my-2" onClick={() => handleShareClic(row)}><FaShareAlt /></button>
                </CopyToClipboard>






            </div>
        },
        {
            name: 'Título',
            selector: 'titulo',
            sortable: true,
            grow: 6
        },
        {
            name: 'Categoría',
            selector: 'categoria',
            sortable: true,
            grow: 2,
            cell: row => categorias.filter(categoria => categoria.value === row.idCategoria)[0].label
        }
        ,
        {
            name: 'Sub-categoría',
            selector: 'subcategoria',
            sortable: true,
            grow: 2,
            cell: row => row.idSubcategoria ? subcategorias.filter(subcategoria => subcategoria.value === row.idSubcategoria)[0].label : "-"
        }
        ,
        {
            name: 'Materia',
            selector: 'materia',
            sortable: true,
            grow: 2,
            cell: row => row.idMateria ? materias.filter(materia => materia.value === row.idMateria)[0].label : "-"
        }


    ];


    return (

        filteredBooks ? <GenericTable
            title="Libros"
            columns={columns}
            data={filteredBooks}
            newBtnLabel={<span><IoMdAdd /> Nueva</span>}
            handleNewClic={handleNewClic}
        /> : <React.Fragment />


    )
}
