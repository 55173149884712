//Import React Library
import React from 'react'

//Import text input
import TextInput from "../../Reusable/Filters/TextInput"

//A custom title
import { SectionTitle } from "@lexis/ui-library"

// Table with books data
import Table from "./TableServices"

//Impor Modal with from to create and edit category
import Modal from "../../Reusable/Modal/GenericModal"
import ModalBody from "./ModalBodyServices"

// Import context
import { useServicesContext } from "./contextServices"


export default function AccessType() {
    const { textInput, setTextInput, visibleModal, modalTitle, setVisibleModal } = useServicesContext()
    return (

        <div className="col-12">
            <SectionTitle color="var(--primaryText)" title="Administrador de Tipos de Acceso" />
            <div className="row mx-0 w-100 mb-4">
                <TextInput placeholder="Ingrese el nombre del tipo de Acceso" value={textInput} handleChange={setTextInput} />
            </div>

            <Table/>
            <Modal
                show={visibleModal}
                onHide={()=>setVisibleModal(false)}
                modalTitle={modalTitle}
                modalBody={<ModalBody/>}
            />

        </div>

    )
}