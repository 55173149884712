import React, { useState, useContext } from 'react'
import { lhlCategories, lhlSubcategories, lhlMaterias, lhlFindPaises, lhlFindLibros } from "@lexis/admin-api"

//Import global context
import { useGlobalContext } from "../../../Context/context"
// make a new context
export const BooksContext = React.createContext();
export const useBooksContext = () => useContext(BooksContext);

// create the provider
export const BooksProvider = (props) => {
    const { categorias, subcategorias, materias, libros } = useGlobalContext()
    const [filteredBooks, setFilteredBooks] = useState(null)
    const [textInput, setTextInput] = useState(null)
    const [visibleModal, setVisibleModal] = useState(false)
    const [modalTitle, setModalTitle] = useState(false)
    const [action, setAction] = useState("add")
    const [libroId, setLibroId] = useState(null)
    const [selectedCategoria, setSelectedCategoria] = useState({ value: null, label: "Todas las categorías" })
    const [selectedSubcategoria, setSelectedSubcategoria] = useState({ value: null, label: "Todas las sub-categorías" })
    const [selectedMateria, setSelectedMateria] = useState({ value: null, label: "Todas las materias" })

    React.useEffect(() => {
        setFilteredBooks(libros)
    }, [libros])

    React.useEffect(() => {
        let temp;
        /*console.log("selectedCategoria", selectedCategoria)
        console.log("categorias", categorias)
        console.log("filteredSubcategorias", filteredSubcategorias)
        console.log("textInput", textInput)*/
        if (categorias && subcategorias && materias) {

            temp = textInput ? libros.filter(libro => (libro.titulo.toLowerCase().includes(textInput.toLowerCase()))) : libros
            temp = selectedCategoria.value ? temp.filter(data => data.idCategoria === selectedCategoria.value) : temp
            temp = selectedSubcategoria.value ? temp.filter(data => data.idSubcategoria === selectedSubcategoria.value) : temp
            temp = selectedMateria.value ? temp.filter(data => data.idMateria === selectedMateria.value) : temp
        }
        else {
            temp = libros
        }

        setFilteredBooks(temp)
    }, [textInput, selectedCategoria, selectedSubcategoria, selectedMateria, libros])


    const handleTextInputChange = (value) => {
        setTextInput(value)
        console.log("Text Input:", value)
    }


    return (
        <BooksContext.Provider value={{
            filteredBooks, setFilteredBooks,
            textInput, setTextInput,
            handleTextInputChange,
            visibleModal, setVisibleModal,
            modalTitle, setModalTitle,
            action, setAction,
            libroId, setLibroId,
            selectedCategoria, setSelectedCategoria,
            selectedSubcategoria, setSelectedSubcategoria,
            selectedMateria, setSelectedMateria
        }}>
            {props.children}
        </BooksContext.Provider>
    )
}


