import React from 'react'
import GenericTable from "../../Reusable/Table/GenericTable"
import { FaPencilAlt, FaTrash, IoMdAdd } from "@lexis/ui-library"
import { useSubcategoriesContext } from "./contextSubcategories"
import { useGlobalContext } from "../../../Context/context"
import { lhlDeleteSubcategory } from "@lexis/admin-api"
export default function Table(props) {
    const { setModalTitle, filteredSubcategorias, setVisibleModal, setAction, setidSubcategoria } = useSubcategoriesContext()
    const { fetchSubcategories, categorias } = useGlobalContext()

    const handleDeleteBtnClic = (row) => {
        lhlDeleteSubcategory(row.value).then(({ state, error }) => {
            if (state === "ERROR") {

              error.json().then((data) => {alert(`Error: ${data.subcategoria}`)})

            }
            else {
                fetchSubcategories(categorias)
            }

        })
    }

    const handleEditClic = (row) => {
        console.log("Edit row:", row)
        setModalTitle("Editar Sub-categoría")
        setAction("edit")
        setidSubcategoria(row.value)
        setVisibleModal(true)
    }

    const handleNewClic = (row) => {
        console.log("New row:", row)
        setModalTitle("Añadir Sub-categoría")
        setAction("add")
        //setidCategoria(row.value)
        setVisibleModal(true)
    }

    const columns = [
        {
            name: 'Sub-categoría',
            selector: 'subcategoria',
            sortable: true,
            grow: 2,
            //cell: row => categorias.filter(categoria => categoria.value === row.idCategoria)[0].label
        }
        ,
        {
            name: 'Categoría',
            selector: 'categoria',
            sortable: true,
            grow: 2,
            cell: row => row.categoria.categoria
        }
        ,

        {
            name: 'Link',
            selector: 'link',
            sortable: true,
            grow: 2
        },
        {
            name: 'Acciones',
            selector: 'id',
            sortable: false,
            grow: 2,
            cell: row => <div className="row mx-0">
                <button className="btn my-button mr-2" onClick={() => handleEditClic(row)}><FaPencilAlt /></button>
                <button className="btn my-button ml-2" onClick={() => handleDeleteBtnClic(row)}><FaTrash /></button>
            </div>
        }
    ];


    return (

        filteredSubcategorias ? <GenericTable
            title="Sub-categorías"
            columns={columns}
            data={filteredSubcategorias}
            newBtnLabel={<span><IoMdAdd /> Nueva</span>}
            handleNewClic={handleNewClic}
        /> : <React.Fragment />


    )
}
