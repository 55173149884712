//Import React Library
import React, { useState } from 'react'

//Load states from state management provider
import { useSubjectsContext } from "./contextSubjects"
import { useGlobalContext } from "../../../Context/context"

//Import components from Lexis UI Library
import { Dropzone, Alert, makeAnimated, Select } from "@lexis/ui-library"

//Import fucntion from LHL admin api component
import { lhlSaveMateria, lhlUpdateMateria, lhlFindMateria } from "@lexis/admin-api"

//define helpers
const animatedComponents = makeAnimated();


export default function ModalSubjects(props) {
    const { action, idMateria } = useSubjectsContext();
    const { fetchSubjects, categorias, subcategorias } = useGlobalContext();
    //Define states  
    const [formCategoria, setFormCategoria] = useState(null)
    const [formSubcategoria, setFormSubcategoria] = useState(null)    
    const [formTitulo, setFormTitulo] = useState(null)
    const [alertMessage, setAlertMessage] = useState("")
    const [alertVariant, setAlertVariant] = useState("")
    const [visible, setVisible] = useState(false);

    React.useEffect(() => {
        console.log("idMateria:", idMateria)
        if ((action === "edit") & (idMateria !== null)) {
            lhlFindMateria(idMateria).then((data) => {
                console.log("fin materia: ", data)
                let temp=data.data
                setFormCategoria({ value: temp.subcategoria.categoria.id, label: temp.subcategoria.categoria.categoria })
                setFormSubcategoria({ value: temp.subcategoria.id, label: temp.subcategoria.subcategoria  })
                setFormTitulo(temp.materia)

            })
        }
    }, [idMateria, action])

    React.useEffect(() => {
        console.log("action:", action)
        if (action === "add") {

            resetFormValues()

        }
    }, [action])

    const resetFormValues = () => {
        setFormCategoria("")
        setFormSubcategoria("")
        setFormTitulo("")
        setAlertMessage("")
        setAlertVariant("")
        setVisible(false)

    }



    const handleSubmitAction = () => {
        
        if (action === "add") {
            lhlSaveMateria(formSubcategoria ? formSubcategoria.value : null, formTitulo).then((data) => {
                console.log("save materia: ", data)
                handleApiResponse(data)
            })
        }
        else {
            lhlUpdateMateria(idMateria, formSubcategoria ? formSubcategoria.value : null, formTitulo).then((data) => {
                console.log("save libro: ", data)
                handleApiResponse(data)
            })
        }
    }

    const handleApiResponse = (data) => {
        if (data.state === "SUCCESS") {
            setAlertMessage("Proceso Exitoso!")
            setAlertVariant("success")
            setVisible(true)
            //resetFormValues()
            fetchSubjects(subcategorias)
        }
        else {
            let errorMsg = ""
            if (data.error === 400) { errorMsg = "Por favor llene todos los campos requeridos" }
            setAlertMessage("Error: " + errorMsg)
            setAlertVariant("danger")
            setVisible(true)
        }
    }
    console.log("subcategorias",subcategorias)
    console.log("formCategoria",formCategoria)
    console.log("dropdown", subcategorias.filter(subcategoria => subcategoria.categoria.id ))
    console.log("dropdown", subcategorias.filter(subcategoria => subcategoria.categoria.id === (formCategoria ? formCategoria.value : null)))
    console.log("dropdown", subcategorias[0].categoria)
    console.log("dropdown", subcategorias[0].categoria.id)
    console.log("dropdown", formCategoria?.value)
    return (
        <div className="row mx-0 w-100">


            <div className="col-12 py-2">
                <label for="ftitulo" className="form-label">Nombre * </label>
                <input type="text" className="form-input px-4" id="ftitulo" name="ftitulo" onChange={e => setFormTitulo(e.target.value)} value={formTitulo} />
            </div>

            <div className="col-12 py-2">
                <label className="form-label">Categoría *</label>
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    placeholder="Categoría"
                    value={formCategoria}
                    //defaultValue={categorias?categorias[1]:null}
                    onChange={e => (setFormCategoria(e), setFormSubcategoria(null))}
                    options={categorias}
                />
            </div>

            <div className="col-12 py-2">
                <label className="form-label">Subcategoría *</label>
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    placeholder="Subcategoría"
                    isDisabled={formCategoria ? false : true}
                    value={formSubcategoria}
                    //defaultValue={options[1]}
                    onChange={e => setFormSubcategoria(e)}
                    options={subcategorias && subcategorias.filter(subcategoria => subcategoria.categoria.id === (formCategoria ? formCategoria.value : null))}
                />
            </div>



            <button className="btn my-button mx-auto my-3" onClick={() => handleSubmitAction()}>{action === "add" ? "Añadir Materia" : "Editar Materia"}</button>


            {visible && <Alert variant={alertVariant} onClose={() => setVisible(false)} dismissible className="w-100">
                <p>
                    {alertMessage}
                </p>
            </Alert>}

        </div >
    )

}
