//Import React Library
import React from 'react'

//Import state management provider
import { SubcategoriesProvider } from "./contextSubcategories"

//Import Categories Layout
import Subcategories from "./Subcategories"
export default function Index() {

    return (
        <SubcategoriesProvider>
            <Subcategories/>
        </SubcategoriesProvider>
    )
}
