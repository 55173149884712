import React, { useState, useContext } from 'react'
import { lhlCategories, lhlSubcategories, lhlMaterias, lhlFindPaises, lhlFindLibros } from "@lexis/admin-api"

//Import global context
import { useGlobalContext } from "../../../Context/context"
// make a new context
export const SubjectsContext = React.createContext();
export const useSubjectsContext = () => useContext(SubjectsContext);

// create the provider
export const SubjectsProvider = (props) => {
    const { categorias, subcategorias, materias } = useGlobalContext()
    const [filteredSubjects, setFilteredSubjects] = useState(null)
    const [textInput, setTextInput] = useState(null)
    const [visibleModal, setVisibleModal] = useState(false)
    const [modalTitle, setModalTitle] = useState(false)
    const [action, setAction] = useState("add")
    const [idMateria, setidMateria] = useState(null)
    const [selectedCategoria, setSelectedCategoria] = useState({ value: null, label: "Todas las categorías" })
    const [selectedSubcategoria, setSelectedSubcategoria] = useState({ value: null, label: "Todas las sub-categorías" })

    React.useEffect(() => {
        setFilteredSubjects(materias)
    }, [materias])

    React.useEffect(() => {
        let temp;
        /*console.log("selectedCategoria", selectedCategoria)
        console.log("categorias", categorias)
        console.log("filteredSubcategorias", filteredSubcategorias)
        console.log("textInput", textInput)*/
        console.log("filter materias:", materias)
        if (categorias && subcategorias && materias) {

            temp = textInput ? materias.filter(materia => (materia.materia?.toLowerCase().includes(textInput.toLowerCase()) || materia.subcategoria.subcategoria?.toLowerCase().includes(textInput.toLowerCase()) || materia.subcategoria.categoria.categoria?.toLowerCase().includes(textInput.toLowerCase()))) : materias
            temp = selectedCategoria.value ? temp.filter(data => data.categoria === selectedCategoria.label) : temp
            temp = selectedSubcategoria.value ? temp.filter(data => data.subcategoria === selectedSubcategoria.label) : temp
        }
        else {
            temp = materias
        }

        setFilteredSubjects(temp)
    }, [textInput, selectedCategoria, selectedSubcategoria])


    const handleTextInputChange = (value) => {
        setTextInput(value)
        console.log("Text Input:", value)
    }


    return (
        <SubjectsContext.Provider value={{
            filteredSubjects, setFilteredSubjects,
            textInput, setTextInput,
            handleTextInputChange,
            visibleModal, setVisibleModal,
            modalTitle, setModalTitle,
            action, setAction,
            idMateria, setidMateria,
            selectedCategoria, setSelectedCategoria,
            selectedSubcategoria, setSelectedSubcategoria
        }}>
            {props.children}
        </SubjectsContext.Provider>
    )
}


